import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'
import { Title } from '../../../components/Title'

import { FontGenerator } from '../index'

import {
  circled, circledBlack,
  doublestruck,
  fraktur, frakturBold,
  monospace,
  sans,
  sansBold,
  sansBoldItalic,
  sansItalic,
  script, scriptBold,
  serifBold, serifBoldItalic,
  squared, squaredBlack
} from '../functions'

import { table } from './constants'

import styles from './styles.module.scss'
import { strikethroughGeneratorTool, underlineTextTool } from '../../tools'

const fancyVariants = [
  { name: 'Gothic / Blackletter', fn: fraktur },
  { name: 'Gothic / Blackletter bold', fn: frakturBold },
  { name: 'Circled / enclosed alphanumerics', fn: circled },
  { name: 'Negative circled / enclosed alphanumerics', fn: circledBlack },
  { name: 'Monospace / typewriter', fn: monospace },
  { name: 'Double struck / outlined / blackboard bold', fn: doublestruck },
  { name: 'Script / cursive / handwriting', fn: script },
  { name: 'Bold script / cursive / handwriting', fn: scriptBold },
  { name: 'Squared', fn: squared },
  { name: 'Negative squared', fn: squaredBlack },
  { name: 'Serif bold', fn: serifBold },
  { name: 'Serif bold italic', fn: serifBoldItalic },
  { name: 'Sans serif', fn: sans },
  { name: 'Sans serif bold', fn: sansBold },
  { name: 'Sans serif italic', fn: sansItalic },
  { name: 'Sans serif bold italic', fn: sansBoldItalic }
]

export const previewText = fancyVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Italics' },
  { name: 'Cursive' }
]

const slides = [
  {
    slide: '𝔉𝔯𝔞𝔨𝔱𝔲𝔯',
    title: 'Fraktur / Blackletter / Gothic',
    text: <>
      <p>Fraktur is a form of Blackletter or Gothic script. It was first used to decorate religious texts, including the earliest printed book: the Gutenberg Bible.</p>
      <p>It looks great as a heading, but it can be hard to read. Avoid using it in large blocks of text.</p>
    </>
  },
  {
    slide: '𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎',
    title: 'Monospace / typewriter',
    text: <>
      <p>Monospaced fonts were created for typewriters. For practical purposes, every character needed to be the same width.</p>
      <p>They’re still used in computer terminals and code editors as they align each line of code.</p>
      <p>You can also use these characters to create tables and calendars, like in the Unicode calendar generator.</p>
    </>
  },
  {
    slide: '𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜',
    title: 'Double struck / outlined / blackboard bold',
    text: <>
      <p>Double struck is also known as blackboard bold. It originated as an attempt to create bold lettering with chalk on a blackboard.</p>
      <p>The style worked its way into mathematical equations. Later, it was emulated on typewriters by overlaying the same character twice, leading to the name “double struck”.</p>
    </>
  },
  {
    slide: '𝒮𝒸𝓇𝒾𝓅𝓉',
    title: 'Script / cursive / handwriting',
    text: <>
      <p>Script is an attempt to emulate cursive handwriting. The letters aren’t actually connected, but they have a looped, leaning appearance that looks familiar yet personal.</p>
    </>
  },
  {
    slide: '🅒🅘🅡🅒🅛🅔🅓',
    title: 'Circled / enclosed alphanumerics',
    text: <>
      <p>Circled or enclosed alphanumerics are useful for making your text stand out. The circled characters can be used individually as bullet points or in a string of letters and words.</p>
    </>
  },
  {
    slide: '🆂🆀🆄🅰🆁🅴🅳',
    title: 'Squared / enclosed alphanumerics',
    text: <>
      <p>Squared characters have a wide variety of uses. The first ones were added to unicode for Japanese television stations. They needed symbols for like 🅆 for wide format broadcasting, and 🄽 for news.</p>
      <p>Later, Unicode added 🅰 🅱 🅾 and 🆎 for blood types, 🆏 for public toilets, and 🅿 for parking. These were quickly followed by the rest of the alphabet.</p>
      <p>Note: Some of these symbols render as emojis in certain applications.</p>
    </>
  }
]

const Fancy: React.FC = () => {
  return (
    <FontGenerator
      variants={fancyVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, underlineTextTool]}
    >
      <TextGroup title='How does this tool work?' style={{ marginTop: 64 }}>
        <p>
          Our fancy font generator converts regular characters into Mathematical Alphanumeric Symbols.
          These characters were added to Unicode primarily for mathematicians to write equations.
        </p>
        <p>After you type in your text, the tool uses corresponding characters — such as 𝖊, 𝕖, and 𝓮 — to produce fancy font options.</p>
      </TextGroup>
      <TextGroup title='What is Unicode?' style={{ marginTop: 48 }}>
        <p>Unicode is a system which ensures that characters appear the same across any program or platform.</p>
        <p>Computers only use binary to store and send data, which means that all characters are actually made through a series of 1s and 0s.</p>
        <p>Unicode is a practical agreement to use the same 1s and 0s to represent each letter, symbol, or emoji.</p>
      </TextGroup>
      <Table style={{ marginTop: 32 }}>
        {table}
      </Table>
      <TextGroup style={{ marginTop: 32 }}>
        Unicode is a dictionary of all these numbers and to which characters they correspond. There are currently over 137,000 characters.
      </TextGroup>
      <TextGroup title='Where can I use these fancy fonts?' style={{ marginTop: 64 }}>
        You can use fancy fonts pretty much everywhere online, including social media posts, bios,
        and comments on sites like Facebook, X, Reddit, Instagram, and Quora.
        They can also be included in emails, instant messages, browser bookmarks, Wi-Fi networks, and lots more. They even show up in Google results.
      </TextGroup>
      <Title size={3} style={{ marginTop: 64 }}>About the font styles</Title>
      <Carousel
        showThumbs={false}
        showArrows={true}
        dynamicHeight
        showStatus={false}
        infiniteLoop
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={50}
      >
        {slides.map(slide => {
          return (
            <div className={styles.slide} key={slide.title}>
              <div className={styles.image}><div>{slide.slide}</div></div>
              <div>
                <Title className={styles.title} size={4} h={7}>{slide.title}</Title>
                <div className={styles.text}>{slide.text}</div>
              </div>
            </div>
          )
        })}
      </Carousel>
    </FontGenerator>
  )
}

export default Fancy
